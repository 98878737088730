import { useEffect, useState } from 'react';

import fetchSearchTerms from '../utils/fetchSearchTerms';

export const MINIMUM_SEARCH_TERM_LENGTH = 3;

/**
 * Depending on the current entered search term, query the search API for a list of suggested search terms.
 * @param {string} searchTerm Search term
 * @returns {Array<{ term: string, slug: string }>} Suggested search terms from search API. Excludes exact matches with `searchTerm`.
 * `term` field is lowercase in returned records.
 *
 * This hook is adapted from the "Server Side Search" example on https://reach.tech/combobox.
 */
export default function useServerSideSuggestionMatch(searchTerm) {
	const [suggestions, setSuggestions] = useState([]);

	const preparedTerm = searchTerm.trim().toLocaleLowerCase();

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (preparedTerm.length >= MINIMUM_SEARCH_TERM_LENGTH) {
			let isFresh = true;

			fetchSearchTerms(preparedTerm)
				.then((terms) => {
					if (isFresh) {
						setSuggestions(terms);
					}
				});

			return () => {
				isFresh = false;
			};
		}
	}, [preparedTerm]);

	// Ensure returned terms are displayed in lowercase and exclude perfect matches.
	// Since we have a verbatim option on the search bar, there is no need to include perfect matches.
	const preparedSuggestions = suggestions.length > 0 ? (
		suggestions
			.map((suggestion) => ({ term: suggestion.term.toLocaleLowerCase(), slug: suggestion.slug }))
			.filter((suggestion) => suggestion.term !== preparedTerm)
	) : [];

	return preparedSuggestions;
}
