import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, useRouter } from 'next/router';

import { sendPageView, sendPageInteraction } from '../../utils/analytics';

/**
 *
 * @param {object} props Props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function AdobeAnalytics({ children = null }) {
	const router = useRouter();
	// Sends page view and page interaction to Adobe Analytics
	useEffect(() => {
		window.addEventListener('load', () => {
			sendPageView(router.pathname);
			sendPageInteraction();
		});

		return () => {
			window.removeEventListener('load', () => {
				sendPageView(router.pathname);
				sendPageInteraction();
			});
		};
	}, [router]);

	useEffect(() => {
		Router.events.on('routeChangeComplete', sendPageView);
		Router.events.on('routeChangeComplete', sendPageInteraction);

		return () => {
			Router.events.off('routeChangeComplete', sendPageView);
			Router.events.off('routeChangeComplete', sendPageInteraction);
		};
	}, []);

	return children;
}

AdobeAnalytics.propTypes = {
	children: PropTypes.node,
};
