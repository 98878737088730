import PropTypes from 'prop-types';

import HeaderLogo from './HeaderLogo';
import HeaderSearchToggleButton from './HeaderSearchToggleButton';
import Menu from './Menu';
import MenuToggleButton from './MenuToggleButton';
import SearchMenu from './SearchMenu';
import SkipToMainContentLink from './SkipToMainContentLink';

/**
 * @summary The Header without any behaviour. In other words, just the markup.
 * Renders a header nav that is sticky on mobile devices.
 * @param {import('./HeaderTypes').HeaderProps} props
 * @returns {JSX.Element}
 */
export default function Header({
	mainMenuItems = [],
	searchId = '',
	isMenuOpen = false,
	isSearchOpen = false,
	toggleNavMenu = () => {},
	toggleSearchMenu = () => {},
	setSearchOpen = () => {},
}) {
	return (
		<header
			id="header"
			className={[
				'header',
				isMenuOpen ? 'menu-open' : null,
				isSearchOpen ? 'search-open' : null,
			].join(' ')}
		>
			<SkipToMainContentLink />
			<div className="header-wrapper">
				<div className="header-navbar">
					<MenuToggleButton toggleNavMenu={toggleNavMenu} isMenuOpen={isMenuOpen} isMobileOnly />
					<HeaderLogo />
					<Menu mainMenuItems={mainMenuItems} isDesktopOnly />
					<HeaderSearchToggleButton toggleSearchMenu={toggleSearchMenu} isSearchOpen={isSearchOpen} />
				</div>
				<Menu mainMenuItems={mainMenuItems} isMobileOnly />
				<SearchMenu
					searchId={searchId}
					shouldFocus={isSearchOpen}
					setSearchOpen={setSearchOpen}
					toggleSearchMenu={toggleSearchMenu}
				/>
			</div>
		</header>
	);
}

Header.propTypes = {
	mainMenuItems: PropTypes.arrayOf(PropTypes.shape({
		node: PropTypes.shape({
			url: PropTypes.string.isRequired,
			target: PropTypes.string,
			label: PropTypes.string.isRequired,
		}),
	})).isRequired,
	searchId: PropTypes.string.isRequired,
	isMenuOpen: PropTypes.bool,
	isSearchOpen: PropTypes.bool,
	setSearchOpen: PropTypes.func.isRequired,
	toggleNavMenu: PropTypes.func,
	toggleSearchMenu: PropTypes.func,
};
