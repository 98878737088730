import PropTypes from 'prop-types';
import Link from 'next/link';
import { addTrailingSlash } from '../../utils/utility';

/**
 * @summary A nav menu item.
 * @param {import('./HeaderTypes').NavMenuItemProps} props
 * @returns {JSX.Element}
 */
export default function NavMenuItem({ url, target = '', label }) {
	const href = addTrailingSlash(url);

	return (
		(
			<Link
				href={href}
				className="nav-link"
				target={target}
				rel={target === '_blank' ? 'noopener noreferrer' : undefined}
			>

				{label}

			</Link>
		)
	);
}

NavMenuItem.propTypes = {
	url: PropTypes.string.isRequired,
	target: PropTypes.string,
	label: PropTypes.string.isRequired,
};
