import { useEffect } from 'react';

/**
 * @summary Trigger a given action(function) when user click out side of the given element.
 *
 * @param {React.MutableRefObject<T>} mainElementRef React.MutableRefObject of dom.
 * @param {boolean} triggerAction Is dom element visible/open?
 * @param {() => void} actionOnExternalClick Action to run when user clicks outside of given element.
 * @returns {void}
 */
export default function useActOnExternalClick(mainElementRef, triggerAction, actionOnExternalClick) {
	useEffect(() => {
		if (!triggerAction) return () => {};

		function handleExternalClick(event) {
			if (triggerAction && mainElementRef.current && !mainElementRef.current.contains(event.target)) {
				actionOnExternalClick();
			}
		}

		function handleESC(event) {
			if (event.keyCode === 27) {
				if (triggerAction && mainElementRef.current && !mainElementRef.current.contains(event.target)) {
					actionOnExternalClick();
				}
			}
		}

		document.addEventListener('mousedown', handleExternalClick);
		document.addEventListener('keydown', handleESC, false);

		return () => {
			document.removeEventListener('mousedown', handleExternalClick);
			document.removeEventListener('keydown', handleESC, false);
		};
	}, [mainElementRef, triggerAction, actionOnExternalClick]);
}
