import Link from 'next/link';
import htmlParser, { domToReact } from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import globalValues from '../static-data/global-values.json';
import { STANDARD_CONFIG } from '../components/Text/HtmlWrapper';

/**
 * @summary A no-op function. Useful as a no-op function for event handlers and effectful code.
 * Does nothing and returns immediately.
 */
export function noop() {}

/**
 * @summary The identity function. Useful as a no-op for transformers, e.g., `Array.prototype.map()`.
 * @template T
 * @param {T} x
 * @returns {T}
 */
export function identity(x) {
	return x;
}

/**
 * @summary Try a function that may fail, returning a default value if it fails.
 * Very simple wrapper around try-catch to consume errors.
 *
 * @example
 * For instance, `htmlToReact` uses this to safely parse a script source URL:
 * ```javascript
 * const scriptUrl = tryOrDefault(() => new URL(scriptSrc), '');
 * const scriptPathname = scriptUrl?.pathname;
 * ```
 *
 * @template T
 * @template U
 * @template R
 * @param {(T) => U} func A function to try.
 * @param {R} defaultValue A default value to use if func throws an error.
 */
export function tryOrDefault(func, defaultValue = null) {
	try {
		return func();
	} catch (e) {
		return defaultValue;
	}
}

/**
 * Remove non-unique elements from an array using the `Set()` constructor.
 * @template T
 * @param {Array<T>} xs
 * @returns {Array<T>}
 */
export function uniq(xs) {
	return Array.from(new Set(xs));
}

export function uniqByKey(xs, key) {
	return [...new Map(xs.map((x) => [x[key], x])).values()];
}

/**
 * @summary Is this a non-empty array?
 * @template T
 * @param {ArrayLike<T>} xs Array-like object to test.
 * @returns {boolean}
 */
export function arrayHasItems(xs) {
	return (Array.isArray(xs) && (xs.length > 0));
}

/**
 * @summary Copy an array, sort it in-place, and then return the sorted array.
 *
 * Especially useful for incoming React props, which may be frozen. Rather than mutating
 * the incoming prop via `Array.prototype.sort();`, you can use this to make a copy of it.
 *
 * @template T
 * @param {Array<T>} xs Array to copy.
 * @param {(a: T, b: T) => number} [compareFn] Optional compare function.
 * @returns
 */
export function copySort(xs, compareFn = undefined) {
	const copied = xs.slice();

	if (typeof compareFn !== 'undefined') {
		copied.sort(compareFn);
	} else {
		copied.sort();
	}

	return copied;
}

/**
 * @summary Find and replace substring in a string.
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 * @returns {string}
 */
export function findAndReplaceInString(str, find, replace) {
	if (typeof str === 'string' || str instanceof String) {
		return str.split(find).join(replace);
	}

	return str;
}

/**
 * @summary Truncate a string so that the words fit in `maxLength` without breaking any words.
 * @param {string} str
 * @param {number} maxLength
 */
export function truncateString(str, maxLength) {
	const end = str.lastIndexOf(' ', maxLength);

	if (end === -1) {
		return str;
	}

	return str.substring(0, end);
}

/**
 * @summary Wrap with quotes.  Front and back.  Defualt quote is set to double quotes (")
 * @param {string} str String to wrap.
 * @param {string} [quote] Quotation mark to wrap string with.
 * @returns {string}
 */
export function wrapWithQuotes(str, quote = '"') {
	return (quote + str + quote);
}

/**
 * Remove HTML tags from a string.
 * Can be used in Node.js contexts.
 * @param {string} content String of raw HTML.
 * @returns
 */
export function removeHtmlTags(content) {
	return content.replace(/(<([^>]+)>)/ig, '');
}

/**
 * @summary Compares two arrays and returns whether their values are the same and in the same order.
 * @template A
 * @template B
 * @param {Array<A>} a
 * @param {Array<B>} b
 * @returns {boolean}
 */
export const shallowArrayEquals = (a, b) => {
	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i += 1) {
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
};

/**
 * @summary Check if string is a valid recipe quantity value. Ie. A whole number or a fraction
 * @param {string} s String to test.
 * @returns {boolean}
 */
export const isValidRecipeQuantity = (s) => {
	// Test for positive integer or fraction string, eg. "1/4"
	if (/^[1-9][0-9]*(?:\/[1-9][0-9])*/g.test(s)) {
		return true;
	}

	// Test for fractional symbol
	if (/[¼½¾⅐⅑⅒⅓⅔⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]/.test(s)) {
		return true;
	}

	return false;
};

/**
 * @summary Format minutes to hour min format
 * @param {number} minutes to be converted
 * @returns {string} formatted time in (x)h (x)min
 */
export const formatMinutes = (minutes) => {
	let result = '';
	if (!minutes) {
		return result;
	}

	const hours = Math.floor(minutes / 60);
	const restMinutes = (minutes % 60);

	const hourTemplate = restMinutes ? 'hr' : (hours > 0 ? 'hour' : '') + (hours > 1 ? 's' : '');

	result += hours ? `${hours} ${hourTemplate} ` : '';
	result += restMinutes ? `${restMinutes} min` : '';
	return result;
};

/**
 * @summary Convert a number of seconds into a string with a `'hh:mm:ss`' format.
 * @param {number | string} $duration
 * @returns {string} Seconds into a string with `'hh:mm:ss'` format.
 */
export const secondsToMinutes = ($duration) => {
	const $seconds = parseFloat($duration); // Just in case it's a string
	let result = '';
	if ($seconds >= 3600) {
		result = new Date($seconds * 1000).toISOString().substr(11, 8);
	} else {
		result = new Date($seconds * 1000).toISOString().substr(14, 5);
	}
	return result;
};

/**
 * @summary Load script dynamically from source (src) value.
 * @param {string | number} id Unique ID to add to the injected `script` element's ID attribute.
 * @param {string} src Source URL for the script.
 * @param {(() => {}) | null | undefined} callbackFunction Callback function to run when the script loads.
 */
export const loadDynamicScript = (id, src, callbackFunction) => {
	const existingScript = document.getElementById(id);

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = src;
		script.id = id;
		document.body.appendChild(script);

		script.onload = () => {
			if (callbackFunction) callbackFunction();
		};
		return;
	}

	if (existingScript && callbackFunction) callbackFunction();
};

/**
 * @summary Round number with decimal place
 * @param {number} value Number to round.
 * @param {number} precision Precision of rounding.
 * @returns {number}
 */
export const roundNum = (value, precision) => {
	const multiplier = (10 ** precision) || 0;
	return Math.round(value * multiplier) / multiplier;
};

/**
 * @summary Hide the Google ReCAPTCHA badge if it is present in the document.
 */
export const hideGoogleCaptchaBadge = () => {
	if (document.getElementsByClassName('grecaptcha-badge')[0]) {
		document.getElementsByClassName('grecaptcha-badge')[0].style.display = 'none';
	}
};

/**
 * @summary Is this string s valid URL?
 * @param {string} str String to test.
 * @returns {boolean}
 */
export const isValidURL = (str) => {
	const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
		+ '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
		+ '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
		+ '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
		+ '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
		+ '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(str);
};

/**
 * @summary gets extension from a url pathname
 * @param {string} url
 * @returns {string} extension
 */
export const getFileExtension = (url) => {
	const regexp = /\.([0-9a-z]+)(?:[?#]|$)/i;
	const ext = url.match(regexp);
	return ext && ext[1];
};

/**
 * @summary Add a trailing slash to a string *if* it does not already have one.
 * @param {string} str String to add a trailing slash to.
 * @returns {string}
 */
export const addTrailingSlash = (str) => {
	// Type-guard against falsy or non-string values passed to this function.
	if (!str || typeof str !== 'string') {
		return str;
	}

	const { pathname } = tryOrDefault(
		// URL is valid if constructor does not throw a `TypeError` (see https://developer.mozilla.org/en-US/docs/Web/API/URL/URL).
		() => new URL(str),
		{ pathname: '' },
	);

	const doesUrlNotNeedTrailingSlash = (
		// If the URL already has a trailing slash, return it as is.
		str.endsWith('/')
		// If the URL ends in a file extension, return it as is.
		// If URL construction fails, `pathname` will be a falsy empty string.
		|| (pathname && getFileExtension(pathname))
	);

	return doesUrlNotNeedTrailingSlash ? str : `${str}/`;
};

/**
 * @summary Add rel="noopener noreferrer" to all external nav links.
 */
export const addNavTabnabbing = () => {
	document.querySelectorAll('a[target=_blank]').forEach((anchor) => {
		if (
			anchor.rel === ''
			|| (!anchor.rel.includes('noopener') && !anchor.rel.includes('noreferrer'))
		) {
			// eslint-disable-next-line no-param-reassign
			anchor.rel = 'noopener noreferrer';
		}
	});
};

/**
 * @summary Isomorphically determine if a URL has the same origin as the website.
 *
 * @author Vinay Tallapalli
 * @param {string} url URL to test.
 * @returns {boolean}
 */
export const isOriginSameAsLocation = (url) => {
	const pageLocation = globalValues?.data?.themeSettings?.themeConfig?.frontendUrl;
	const { hostname } = new URL(pageLocation);

	/**
	 * url could come as /article/easy-taco-recipes/
	 * In this case, we quickly want to verify whether it's a relative URL or not.
	 */
	const relativeUrlMatch = url.match(/^[^/]+\/[^/].*$|^\/[^/].*$/gmi);

	// If we find any match, simply return true.
	if (relativeUrlMatch && relativeUrlMatch.length > 0) {
		return true;
	}

	// Absolute url match
	const matches = url.match(/(http[s]?:\/\/|\w+(?=\.)|\.\w+)/g);

	if (matches) {
		// eslint-disable-next-line no-useless-escape
		const matchHost = matches.join('').match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);

		return !!((matchHost && hostname) && matchHost.includes(hostname));
	}

	return false;
};

/**
 * @summary Replace an anchor DOM Node with a NextLink or a regular anchor based on the following crtieria:
 *
 * 1. If the DOM Node has an internal URL, this function replaces it with a NextLink.
 * 2. If the DOM Node has an external URL, this function replaces it with a regular anchor.
 * 3. If the DOM Node has a `target="_blank"`,
 *    this function also applies a tabnabbing fix by adding `noreferrer` to `rel` if needed.
 * 4. If the DOM Node does NOT have a `target="_blank"`,
 *    this function simply removes the `rel` attribute by setting it to `undefined`.
 *
 * @param {import('html-react-parser').DOMNode} domNode
 * @returns {JSX.Element}
 */
export function replaceLinkWithNextLink(domNode) {
	const linkHref = domNode?.attribs?.href;
	const linkTarget = domNode?.attribs?.target;
	const linkRel = domNode?.attribs?.rel;

	const correctExternalLinkRel = 'noopener noreferrer';

	const doesLinkOpenInNewTab = linkTarget === '_blank';
	const doesLinkRelHaveCorrectValue = linkRel && linkRel === correctExternalLinkRel;

	const htmlTagsToVerify = ['b', 'em', 'i', 'strong'];

	/* #region tabnabbing-fix-for-links-that-open-in-new-tabs */
	/**
	 * If the link opens in a new tab but does NOT have `noreferrer` in its `rel` attribute,
	 * add `noreferrer`. Otherwise, leave it as-is.
	 *
	 * If the link does NOT open in a new tab,
	 * then leave `rel` unitialized as `undefined` so that it disappears as an attribute.
	 *
	 * We're applying this fix for both external and internal links, since editors may choose to
	 * make either open in a new tab.
	 */
	let rel;

	if (doesLinkOpenInNewTab) {
		rel = !doesLinkRelHaveCorrectValue ? correctExternalLinkRel : linkRel;
	}
	/* #endregion */

	/**
	 * Account for any `<b>`, `<strong>`, `<i>`, or `<em>` tags inside a link.
	 */

	const linkContentNodes = domNode.children.map((item) => {
		const children = item?.children ? item.children : null;

		if (children) {
			return `<${item?.name}>${children[0]?.data ?? ''}`;
		}
		return (
			(htmlTagsToVerify.includes(item?.name)) ? (
				`<${item?.name}>${item?.data ?? ''}`
			) : (
				item.data ?? ''
			)
		);
	});

	const linkContent = linkContentNodes.join('');

	const sanitizedLinkContent = DOMPurify.sanitize(linkContent, STANDARD_CONFIG.config);

	return isOriginSameAsLocation(linkHref) ? (
		(
			<Link
				href={linkHref}
				target={linkTarget}
				rel={rel}
				dangerouslySetInnerHTML={{
					__html: sanitizedLinkContent,
				}}
			/>
		)
	) : (
		// ESLint seems to think this is a control?!
		// eslint-disable-next-line jsx-a11y/control-has-associated-label
		<a
			href={linkHref}
			target={linkTarget}
			rel={rel}
			dangerouslySetInnerHTML={{
				__html: sanitizedLinkContent,
			}}
		/>
	);
}

/**
 *
 * @param {string} html String of raw HTML.
 * @returns
 */
export function forceNextLinks(html) {
	if (!html || html === '' || html === null || typeof html !== 'string') {
		return null;
	}

	const options = {
		replace: (domNode) => {
			const href = domNode?.attribs?.href;

			if (domNode.name
				&& domNode.name === 'a'
				&& domNode.type === 'tag'
				&& href
				&& isOriginSameAsLocation(href)
			) {
				const links = domNode.children[0];

				return (
					<Link href={links.parent.attribs.href}>
						{links.data}
					</Link>
				);
			}
			return domToReact(domNode, options);
		},
	};

	const sanitizedContent = DOMPurify.sanitize(html);

	const htmlWithNextLinks = htmlParser(sanitizedContent, options);

	return htmlWithNextLinks;
}

export const uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

/**
 * @summary Determine if a string is a valid UUID.
 * @param {string} str String to test.
 */
export const isValidUuid = (str) => (
	typeof str === 'string' && uuidRegex.test(str.toLowerCase())
);

/**
 * @summary Focus and open keyboard on an input element on iOS.
 *
 * Keyboard is not shown on input.focus() on iOS
 * To get keyboard, create a temp hidden input aligned with the actual element with focus()
 * Once the keyboard is seen, focus the actual element after a delay();
 *
 * @param {HTMLElement} elm Element to focus.
 * @param {number} timeout Delay to focus on the element.
 * @returns {undefined}
 */
export const focusAndOpenKeyboard = (elm, timeout = 100) => {
	if (!elm) {
		return;
	}

	const tempInput = document.createElement('input');
	tempInput.style.height = 0;
	tempInput.style.left = `${elm.offsetLeft}px`;
	tempInput.style.opacity = 0;
	tempInput.style.position = 'absolute';
	tempInput.style.top = `${elm.offsetTop}px`;

	document.body.appendChild(tempInput);
	tempInput.focus();

	const focusElm = () => {
		elm.focus();
		elm.click();
		document.body.removeChild(tempInput);
	};

	setTimeout(focusElm, timeout);
};

/**
 * @summary Remove empty HTML tags from a string of raw HTML.
 * @param {string} string String of raw HTML.
 * @returns {string}
 */
export const RemoveEmptyTags = (string) => (
	// Regex Explanation:
	// (?!div\s+class="corusPlayer"\s*|iframe[^>]*><\/iframe>) exclude video player and iframes
	// ([^>]+) captures tag names and any attibute
	// \s* captures whatever whitespace is after opening tag
	// <\/\1\s*> matches the closing angle and any optional white space before closing
	// eslint-disable-next-line no-useless-escape
	string.replace(/<(?!div\s+class="corusPlayer"\s*|iframe[^>]*><\/iframe>)([^>]+)>\s*<\/\1\s*>/gi, '')
);

/**
 * @summary Make a string's first character uppercase
 * @param {string} string
 * @returns string | empty string
 */
export const ucFirst = (string) => {
	if (!string) return '';

	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @summary Check to see if a string is a valid email address
 * @param {string} string
 * @returns boolean
 */
export const isValidEmailAddress = (string) => {
	// validate email using regex, regex from http://emailregex.com/
	// eslint-disable-next-line max-len, no-useless-escape
	const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return typeof string === 'string' && EMAIL_REGEX.test(string);
};

/**
 * Convert a given date to string format
 * @param {string} date
 * @param {string} language
 * @returns string
 */
export const dateToString = (date, language) => {
	const dateLanguage = language ?? 'en-US';
	const stringDate = new Intl.DateTimeFormat(dateLanguage, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}).format(new Date(date));

	return stringDate;
};

/**
 * Get article the proper date based on the given language
 *
 * @param {string} date
 * @param {string} language
 * @returns string
 */
export const getArticleDate = (date, language) => {
	// get the published date
	const articleDate = new Date(date);

	return dateToString(articleDate, language);
};

/**
 * Check to see if an article is modified after it is published
 * @param {string} publishedDate
 * @param {string} modifiedDate
 * @returns boolean
 */
export const isArticleModified = (publishedDate, modifiedDate) => {
	const articlePublishedDate = new Date(publishedDate);
	const articleModifiedDate = new Date(modifiedDate);

	return (articleModifiedDate >= articlePublishedDate);
};

/**
 * @summary Check to see if a string is a video shortcode
 * @param {string} string
 * @returns boolean
 */
export const isVideoShortcode = (string) => {
	// eslint-disable-next-line max-len, no-useless-escape
	const SHORTCODE_REGEX = /\[(\[?)(corusvideo)(?![\w-])([^\]/]*(?:\/(?!\])[^\]/]*)*)(?:(\/)\]|\](?:([^[]*(?:\[(?!\/\2\])[^[]*)*)\[\/\2\])?)(\])?/g;
	return typeof string === 'string' && SHORTCODE_REGEX.test(string);
};

/**
 * Ease-in-out-quad function.
 * @param {number} t
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @returns {number}
 */
export const easeInOutQuad = (t, b, c, d) => {
	let tIn = t / (d / 2);

	if (tIn < 1) {
		return (c / 2) * tIn * tIn + b;
	}

	tIn -= 1;

	return (-c / 2) * (tIn * (tIn - 2) - 1) + b;
};

/**
 * Animate ease-in-out-quad scrolling to a given Y position on the window, in a given duration.
 * @param {number} final Destination Y position.
 * @param {number} duration Duration of animation in milliseconds.
 * @param {((() => void) | null | undefined)} callbackFunction
 */
export const animateScrollTo = (final, duration, callbackFunction) => {
	const start = window.scrollY || document.documentElement.scrollTop;

	let currentTime = null;

	const animateScroll = (timestamp) => {
		if (!currentTime) {
			currentTime = timestamp;
		}

		let progress = timestamp - currentTime;

		if (progress > duration) {
			progress = duration;
		}

		const yPos = easeInOutQuad(progress, start, final - start, duration);

		window.scrollTo(0, yPos);

		if (progress < duration) {
			window.requestAnimationFrame(animateScroll);
		} else if (callbackFunction) {
			callbackFunction();
		}
	};

	window.requestAnimationFrame(animateScroll);
};

/**
 * @summary Get the slug from a router path
 * @param {string} str String to extract the slug from
 * @returns {string}
 */
export const getSlugFromPath = (str) => {
	// Type-guard against falsy or non-string values passed to this function.
	if (!str || typeof str !== 'string') {
		return str;
	}

	const parts = str.split('/');
	const slug = parts[parts.length - 1];

	return slug;
};

/**
 * This will replace the trailing slash with an empty string
 * @param {string} str
 * @returns {string}
 */
export function removeTrailingSlash(str) {
	return str.replace(/\/$/, '');
}

/**
 * @summary Create array of authors
 * @param {string | Array<{ title?: string }>} creators Incoming list of authors
 * @returns {string | Array<string>}
 */
export const getContentAuthors = (creators) => {
	/** @type {Array<string>} */
	const result = [];

	if (!creators) return [];

	if (Array.isArray(creators)) {
		creators.forEach((creator) => {
			result.push(creator?.title ?? 'Flavour Network');
		});
	} else {
		result.push(creators);
	}

	if (result.length < 1) {
		result.push('Flavour Network');
	}

	return result;
};

/**
 * Format an ISO date to just Y-M-D
 * @param {string} date original ISO date
 * @returns {string} formated date
 */
export const getFormattedDate = (date) => date.slice(0, 10);

/**
 * @summary Get a random alphanumeric ASCII character.
 * @returns {string}
 */
function getRandomAlphanumericAsciiCharacter() {
	const CHARACTER_RANGE = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

	const randomIndex = Math.floor(Math.random() * CHARACTER_RANGE.length);

	return CHARACTER_RANGE[randomIndex];
}

/**
 * @summary Get a random alphanumeric ASCII ID of length `length`
 * @param {number} length Length of the ID to create. Must be greater than 0.
 * @throws `Error` If function is called with a zero or negative `length` argument.
 * @returns {string}
 */
export function getRandomAlphanumericAsciiId(length) {
	if (!(length > 0)) {
		throw new Error('getRandomAlphanumericAsciiId must be called with a non-zero positive length');
	}

	const id = Array(length)
		.fill('')
		.map(() => getRandomAlphanumericAsciiCharacter());

	return id.join('');
}

/**
 * Determine if a given page is French based on the page language
 * @param {string} pageLanguage
 */
export const isFrenchPage = (pageLanguage) => {
	if (!pageLanguage || typeof pageLanguage !== 'string') {
		return false;
	}

	return (pageLanguage === 'fr');
};

/**
 * @summary Determine the correct height of an image based on the new width
 * @param {string} imageWidth original image width
 * @param {string} imageHeight original image height
 * @param {string} newWidth the new width of the image
 */
export function getImageHeightAspectRatio(imageWidth, imageHeight, newWidth) {
	// in case  no original image width and height is provided, return 367
	// since image component is expecting a number for the height
	if ((imageWidth || imageHeight) === null) {
		return 367;
	}

	const aspectRatio = imageHeight / imageWidth;
	const newHeight = Math.round(newWidth * aspectRatio);

	return newHeight;
}

/**
 * @summary Close the search menu if it is open
 */
export function closeSearchMenu() {
	// Get the header element
	const headerElement = document.getElementById('header');
	if (headerElement) {
		// Remove the search open class to close the search
		headerElement.classList.remove('search-open');
	}
}

/**
 * Find the position of a term in list of terms
 * @param string term
 * @param array terms
 * @returns string position
 */
export function getSearchTermPosition(term, terms = []) {
	let position = 0;

	// Check if there are terms
	if (terms && terms.length > 0) {
		// Loop through terms
		terms.forEach((element, index) => {
			// Find the term in the terms list
			if (element.term === term) {
				// Update the position
				position = index + 1;
			}
		});
	}

	return position;
}

/**
 * A simple utility function to create a post object with content
 * @param string html
 * @returns a post object with the content
 */
export function createPostContent(html) {
	return {
		content: html,
	};
}

/**
 * Determine if array of tags consist of vertical video aspect ratio tag
 * @param {array} tags
 * @returns boolean
 */
export function hasVerticalVideoTag(tags) {
	return Array.isArray(tags) && tags.some((item) => item.value === 'video_aspect_ratio_9_16');
}

/**
 * Perform actions on JWPlayer when it is loaded
 * @param number playerIndex
 * @param string action
 * @returns
 */
export const safeJWPlayerAction = (playerIndex, action) => {
	// Ensure JWPlayer is loaded
	if (typeof window.jwplayer !== 'function') {
		console.warn('JWPlayer is not loaded');
		return;
	}

	try {
		const player = window.jwplayer(`corusPlayer${playerIndex}`);
		if (player && typeof player[action] === 'function') {
			player[action]();
		}
	} catch (error) {
		console.error(`Error performing ${action} on player ${playerIndex}:`, error);
	}
};
