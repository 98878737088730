import PropTypes from 'prop-types';
import Link from 'next/link';

/**
 * Render Newsletter link
 * @param {object} props
 * @returns {JSX.Element}
 */
export default function NewsletterLink({ newsletterUrl = '/newsletter', className = 'newsletter-link' }) {
	return (
		<div className={className}>
			<Link href={newsletterUrl}>
				SIGN UP FOR NEWSLETTER
				<span className="visually-hidden">
					on our newsletter page
				</span>
			</Link>
		</div>
	);
}

NewsletterLink.propTypes = {
	newsletterUrl: PropTypes.string,
	className: PropTypes.string,
};
