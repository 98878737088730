import Image from 'next/image';
import PropTypes from 'prop-types';
import path from 'path';
import { BreakPoints } from '../Breakpoints/Breakpoints';
import customImageLoader from './CustomImageLoader';

export default function CorusImage(props) {
	// Need array of just the non-zero width values,
	const breakpoints = Object.values(BreakPoints).filter((width) => width > 0);
	const {
		sizes = null, src, alt = null, className = null, width = 100, height = 100, priority = false,
	} = props;

	if (!src || src == null) return null;

	const sizesString = sizes && typeof sizes === 'object'
		? breakpoints.map((breakpoint, index) => `(max-width: ${breakpoint}px) ${sizes[index]}w`).join(',')
		: `(max-width: ${BreakPoints.lg}px) 100vw, ${BreakPoints.lg}px`;

	/**
	 * The fallback image returns an object that looks like 👇🏼
	 * {
	 *   src: '/_next/static/media/post-fallback-image.bd9f6e02.jpg',
	 *   height: 853,
	 *   width: 1280,
	 *   blurDataURL: '/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fpost-fallback-image.bd9f6e02.jpg&w=8&q=70'
	 * }
	 * Meanwhile, the actual img src looks like https://i0.wp.com/assets.preprod.flavournetwork.ca/wp-content/uploads/2021/08/Anna_Olson-2.jpg
	*  */
	const imgSrc = typeof src === 'string' ? src : src?.src;

	const isSVG = path.extname(imgSrc) === '.svg';

	return (
		<div
			className="corus-image-wrapper"
			style={{
				maxWidth: `${width}px`,
				margin: 'auto',
			}}
		>
			{isSVG ? (
				<img
					src={imgSrc}
					alt={alt}
					width={width}
					height={height}
					sizes={sizesString}
					className={className}
				/>
			) : (
				<Image
					src={imgSrc}
					alt={alt}
					width={width}
					height={height}
					className={className}
					loader={customImageLoader}
					sizes={sizesString}
					style={{
						width: '100%',
						height: 'auto',
					}}
					priority={priority}
				/>
			)}

		</div>
	);
}

CorusImage.propTypes = {
	src: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]).isRequired,
	sizes: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
	]),
	alt: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	priority: PropTypes.bool,
};
