import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'next/router';

import PredictiveSearchForm from './PredictiveSearchForm';

import useServerSideSuggestionMatch from '../../hooks/useServerSideSuggestionMatch';

import { noop, getSearchTermPosition, animateScrollTo } from '../../utils/utility';
import { handleSearchData } from '../../utils/analytics';
import useCurrentWidth from '../../utils/useCurrentWidth';

/**
 * @summary Renders a search bar with autosuggestions.
 * @param {import('./PredictiveSearchBarProps').PredictiveSearchBarProps} props
 * @returns {JSX.Element}
 */
export default function PredictiveSearchBar({
	searchInputId,
	searchInputLabelId,
	searchPlaceholder,
	submitButtonLabel,
	initialValue = '',
	isCompact = false,
	shouldFocus = false,
	onSearch = noop,
	shouldRedirectOnSubmit = false,
}) {
	const searchInputName = 'search-term';

	const [searchTerm, setSearchTerm] = useState(initialValue);
	const [active, setActive] = useState(shouldFocus);
	const inputRef = useRef(null);
	const currentWindowWidth = useCurrentWidth();

	const router = useRouter();

	const handleSearchTermChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleFocus = () => {
		setActive(true);

		if ( currentWindowWidth > 809 ) {
			setTimeout( smoothScrollPosition, 100 );
		}
	};

	// custom smooth scroll
	const smoothScrollPosition = () => {
		if( inputRef.current ) {
			const preSearchBarRect = inputRef.current.getBoundingClientRect(); // get position of search bar respective to viewport
			let preSearchBarScrollPos = window.scrollY + preSearchBarRect.top; // default scroll Position
			const scrollOffset = currentWindowWidth > 1023 ? 50 : 100; // adjusting offset as per the screen size
			preSearchBarScrollPos = preSearchBarScrollPos - scrollOffset;
			const duration = 500; // smooth animation

			animateScrollTo( preSearchBarScrollPos, duration );
		}
	};

	const handleBlur = () => {
		setActive(false);
	};

	useEffect(() => {
		if (shouldFocus) {
			inputRef.current.focus();
		}
	}, [shouldFocus]);

	useEffect(() => {
		if (shouldRedirectOnSubmit) {
			setSearchTerm('');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);

	const matchedSuggestions = useServerSideSuggestionMatch(searchTerm.trim());

	const handleSuggestedTermSelection = (suggestedTerm, suggestions = []) => {
		const submittedSearchTerm = suggestedTerm?.toString()?.trim();
		const searchTermPosition = getSearchTermPosition(suggestedTerm, suggestions);

		/* Input field must not be empty */
		if (!submittedSearchTerm) {
			// Reset search term
			setSearchTerm('');
			return;
		}

		const searchUrl = `/search/${encodeURIComponent(submittedSearchTerm)}?page=1&position=${searchTermPosition}`;

		onSearch(submittedSearchTerm, searchTermPosition);

		// handle search data for analytics for search input on search pages
		if (router.pathname.includes('/search/')) {
			handleSearchData('menu');
		}

		// Navigate to the `/search/[term]/` page.
		if (shouldRedirectOnSubmit) {
			// Reset search term
			setSearchTerm('');

			// update location with new term
			router.push(searchUrl, null, { shallow: false });
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const formData = new FormData(event.currentTarget);

		const submittedSearchTerm = formData.get(searchInputName)?.toString()?.trim();

		handleSuggestedTermSelection(submittedSearchTerm);
	};

	useEffect(() => {
		// update value only for search box in search page
		if (router.query.term && !shouldRedirectOnSubmit) {
			setSearchTerm(router.query.term);
		}
	}, [router.query, shouldRedirectOnSubmit]);

	const componentClass = isCompact ? 'predictive-search-bar-compact' : 'predictive-search-bar-menu';
	const activeClass = active ? `${componentClass}-active` : `${componentClass}-inactive`;

	return (
		<PredictiveSearchForm
			searchInputId={searchInputId}
			searchInputLabelId={searchInputLabelId}
			searchInputName={searchInputName}
			searchInputRef={inputRef}
			searchPlaceholder={searchPlaceholder}
			submitButtonLabel={submitButtonLabel}
			formClasses={[componentClass, activeClass]}
			handleSubmit={handleSubmit}
			handleBlur={handleBlur}
			handleFocus={handleFocus}
			handleSearchTermChange={handleSearchTermChange}
			handleSuggestedTermSelection={handleSuggestedTermSelection}
			isCompact={isCompact}
			searchSuggestions={matchedSuggestions}
		/>
	);
}

PredictiveSearchBar.propTypes = {
	searchInputId: PropTypes.string.isRequired,
	searchInputLabelId: PropTypes.string.isRequired,
	searchPlaceholder: PropTypes.string.isRequired,
	submitButtonLabel: PropTypes.string.isRequired,
	initialValue: PropTypes.string,
	isCompact: PropTypes.bool,
	shouldFocus: PropTypes.bool,
	onSearch: PropTypes.func,
	shouldRedirectOnSubmit: PropTypes.bool,
};
